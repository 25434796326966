
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  name: "about",
  components: {},
  setup() {
    let data = reactive<any>({});

    const open = () => {
      window.location.href = "imeituan://";
    };
    return {
      open,
      ...toRefs(data),
    };
  },
});
